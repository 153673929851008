import { ContentContainer } from '../components/Layout/ContentContainer';
import { Button, Image, Text } from '@dsx/react';
import styles from './ErrorPage.module.css';
import errorIcon from '../404-icon.png';
import {
	getHeadlineVariantForSize,
	getSubHeadlineVariantForSize,
	useDisplayType,
} from '@ds-mgnl/marketing';

const ErrorPage = () => {
	const displayTarget = useDisplayType();
	const headlineVariant = getHeadlineVariantForSize(displayTarget, 'subpage');
	const subHeadlineVariant = getSubHeadlineVariantForSize(displayTarget);

	const goBack = () => {
		window.history.back();
	};

	const goHome = () => {
		window.location.href = '/';
	};

	return (
		<div className={styles['error-page']}>
			<ContentContainer>
				<Image alt="404 page icon" src={errorIcon} width="96px" height="96px" />
				<Text
					className={styles['headline']}
					variant={headlineVariant}
					element="h1"
				>
					Oops, sorry! We can&apos;t find that page.
				</Text>
				<Text
					className={styles['subheadline']}
					variant={subHeadlineVariant}
					element="h1"
				>
					Either something went wrong, or the page doesn&apos;t exist anymore.
				</Text>

				<Button className={styles['button']} onClick={goBack} variant="default">
					Go back
				</Button>
				<Button className={styles['button']} onClick={goHome} variant="default">
					Home
				</Button>
			</ContentContainer>
		</div>
	);
};

export { ErrorPage };
