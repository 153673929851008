import { useMediaQuery } from '@dsx/react';

import type { DisplayTarget } from '../types';

const DESKTOP_MIN_WIDTH = 1024;
const TABLET_MIN_WIDTH = 768;

const PageSize = {
	DESKTOP_MIN_WIDTH,
	TABLET_MIN_WIDTH,
};

const useDisplayType = (): DisplayTarget => {
	const [isMinDesktop, isMinTablet] = useMediaQuery(
		`(min-width: ${PageSize.DESKTOP_MIN_WIDTH}px)`,
		`(min-width: ${PageSize.TABLET_MIN_WIDTH}px)`
	);
	return isMinDesktop ? 'desktop' : isMinTablet ? 'tablet' : 'mobile';
};

export { useDisplayType };
