// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollateralAssetView-module_asset-detail-view_wwS {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
}
.CollateralAssetView-module_image-container_vdu {
	display: block;
	order: -1;
}
.CollateralAssetView-module_image-container_vdu .CollateralAssetView-module_image_huF {
	aspect-ratio: unset;
	display: block;
	width: 100%;
	height: auto;
	object-fit: contain;
	object-position: left center;
	border-radius: 4px;
}
.CollateralAssetView-module_content-container_hhG {
	padding: 2rem 0 0 0;
}
.CollateralAssetView-module_content-container_hhG .CollateralAssetView-module_headline_t4B {
	margin-top: 1rem;
	max-width: 832px;
}
.CollateralAssetView-module_content-container_hhG .CollateralAssetView-module_description_WTh {
	margin-top: 1rem;
	max-width: 768px;
}
.CollateralAssetView-module_content-container_hhG .CollateralAssetView-module_asset-details_aFI {
	margin-top: 1rem;
	max-width: 768px;
}
.CollateralAssetView-module_content-container_hhG .CollateralAssetView-module_button_PSv {
	margin-top: 2rem;
}

@media only screen and (min-width: 1024px) {
	.CollateralAssetView-module_asset-detail-view_wwS {
		grid-template-columns: repeat(2, 1fr);
	}
	.CollateralAssetView-module_content-container_hhG {
		padding: 0 3rem 0 0;
	}
	.CollateralAssetView-module_image-container_vdu {
		order: 0;
	}
	.CollateralAssetView-module_image-container_vdu .CollateralAssetView-module_image_huF {
		aspect-ratio: 4 / 3;
		object-position: center center;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/CollateralLibraryDetailPage/CollateralAssetView.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,qCAAqC;CACrC,mBAAmB;AACpB;AACA;CACC,cAAc;CACd,SAAS;AACV;AACA;CACC,mBAAmB;CACnB,cAAc;CACd,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,4BAA4B;CAC5B,kBAAkB;AACnB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;AACA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;AACA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;AACA;CACC,gBAAgB;AACjB;;AAEA;CACC;EACC,qCAAqC;CACtC;CACA;EACC,mBAAmB;CACpB;CACA;EACC,QAAQ;CACT;CACA;EACC,mBAAmB;EACnB,8BAA8B;CAC/B;AACD","sourcesContent":[".asset-detail-view {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(1, 1fr);\n\talign-items: center;\n}\n.image-container {\n\tdisplay: block;\n\torder: -1;\n}\n.image-container .image {\n\taspect-ratio: unset;\n\tdisplay: block;\n\twidth: 100%;\n\theight: auto;\n\tobject-fit: contain;\n\tobject-position: left center;\n\tborder-radius: 4px;\n}\n.content-container {\n\tpadding: 2rem 0 0 0;\n}\n.content-container .headline {\n\tmargin-top: 1rem;\n\tmax-width: 832px;\n}\n.content-container .description {\n\tmargin-top: 1rem;\n\tmax-width: 768px;\n}\n.content-container .asset-details {\n\tmargin-top: 1rem;\n\tmax-width: 768px;\n}\n.content-container .button {\n\tmargin-top: 2rem;\n}\n\n@media only screen and (min-width: 1024px) {\n\t.asset-detail-view {\n\t\tgrid-template-columns: repeat(2, 1fr);\n\t}\n\t.content-container {\n\t\tpadding: 0 3rem 0 0;\n\t}\n\t.image-container {\n\t\torder: 0;\n\t}\n\t.image-container .image {\n\t\taspect-ratio: 4 / 3;\n\t\tobject-position: center center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asset-detail-view": `CollateralAssetView-module_asset-detail-view_wwS`,
	"image-container": `CollateralAssetView-module_image-container_vdu`,
	"image": `CollateralAssetView-module_image_huF`,
	"content-container": `CollateralAssetView-module_content-container_hhG`,
	"headline": `CollateralAssetView-module_headline_t4B`,
	"description": `CollateralAssetView-module_description_WTh`,
	"asset-details": `CollateralAssetView-module_asset-details_aFI`,
	"button": `CollateralAssetView-module_button_PSv`
};
export default ___CSS_LOADER_EXPORT___;
