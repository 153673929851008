import { MdMenu, StandaloneIcon, classNames } from '@dsx/react';
import styles from './TopNavBarMobile.module.css';

type TopNavBarMobileProps = {
	toggleButtonRef: React.RefObject<HTMLDivElement>;
	isOpen: boolean;
	onSelect: (active: boolean) => void;
};

const TopNavBarMobile = ({
	isOpen,
	toggleButtonRef,
	onSelect,
}: TopNavBarMobileProps) => {
	const handleToggle = () => {
		onSelect(!isOpen);
	};

	return (
		<div
			ref={toggleButtonRef}
			onClick={handleToggle}
			className={styles['dropdown-container']}
		>
			<span
				className={classNames(
					styles['arrow-container'],
					isOpen ? styles['active'] : ''
				)}
			>
				<StandaloneIcon
					icon={MdMenu}
					title={isOpen ? 'Close' : 'Open'}
					size="size-500"
				/>
			</span>
		</div>
	);
};

export { TopNavBarMobile };
