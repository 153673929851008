import type { DisplayTarget } from '../../types';
import type {
	PageHeaderButtonsRaw,
	PageHeroButton,
	PageHeroVariant,
} from './types';

const getHeadlineVariantForSize = (
	dislayTarget: DisplayTarget,
	variant: PageHeroVariant
) => {
	if (dislayTarget == 'desktop') {
		return variant == 'primary' ? 'display-headline-1' : 'display-headline-3';
	}
	if (dislayTarget == 'tablet') {
		return variant == 'primary' ? 'display-headline-2' : 'display-headline-3';
	}
	return variant == 'primary' ? 'display-headline-3' : 'display-headline-4';
};

const getSubHeadlineVariantForSize = (dislayTarget: DisplayTarget) => {
	if (dislayTarget == 'mobile') {
		return 'highlight-2';
	}
	return 'highlight-1';
};

const extractPageHeroButtonProps = (
	buttons: PageHeaderButtonsRaw
): PageHeroButton[] => {
	const buttonKeys = buttons['@nodes'];
	return buttonKeys.reduce((results: PageHeroButton[], key: string) => {
		return [
			...results,
			{
				text: buttons[key].text,
				link: buttons[key].link,
				openInNewTab: buttons[key].openInNewTab,
			},
		];
	}, []);
};

export {
	getHeadlineVariantForSize,
	getSubHeadlineVariantForSize,
	extractPageHeroButtonProps,
};
