// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage-module_headline_bDv {
	max-width: 832px;
	margin-top: 1rem;
}
.ErrorPage-module_subheadline_efD {
	max-width: 768px;
	margin-top: 1rem;
}
.ErrorPage-module_button_z1g {
	margin-top: 2rem;
	margin-right: 1.5rem;
}
.ErrorPage-module_error-page_zDW {
	background-color: var(--color-gray-50);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ErrorPage.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;AACA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;AACA;CACC,gBAAgB;CAChB,oBAAoB;AACrB;AACA;CACC,sCAAsC;AACvC","sourcesContent":[".headline {\n\tmax-width: 832px;\n\tmargin-top: 1rem;\n}\n.subheadline {\n\tmax-width: 768px;\n\tmargin-top: 1rem;\n}\n.button {\n\tmargin-top: 2rem;\n\tmargin-right: 1.5rem;\n}\n.error-page {\n\tbackground-color: var(--color-gray-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": `ErrorPage-module_headline_bDv`,
	"subheadline": `ErrorPage-module_subheadline_efD`,
	"button": `ErrorPage-module_button_z1g`,
	"error-page": `ErrorPage-module_error-page_zDW`
};
export default ___CSS_LOADER_EXPORT___;
