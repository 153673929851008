import { StandaloneIcon, Text, classNames } from '@dsx/react';
import styles from './Footer.module.css';
import type { ReactNode } from 'react';
import type { FooterLink, FooterLinkBlock, SocialLink } from './types';
import { Link } from 'react-router-dom';
import { useDisplayType } from '../../hooks';

interface FooterProps {
	contact: ReactNode;
	copyright: string;
	linkBlocks: FooterLinkBlock[];
	logo: JSX.Element;
	policyLinks: FooterLink[];
	socialLinks: SocialLink[];
}

const renderLinkList = (links: FooterLink[]) => (
	<ul>
		{links.map((link, index) => (
			<li key={`${index}-${link.text.replace(' ', '_').toLowerCase()}`}>
				<Link to={link.url} target={link.openInNewTab ? '_blank' : '_self'}>
					<Text variant="menu-item">{link.text}</Text>
				</Link>
			</li>
		))}
	</ul>
);

const renderLinkBlocks = (linkBlocks: FooterLinkBlock[]) =>
	linkBlocks.map((block, index) =>
		renderBlock({
			title: block.category,
			key: `links-block-${index}`,
			children: renderLinkList(block.links),
		})
	);

const renderBlock = ({
	title,
	key,
	children,
}: {
	title?: string;
	key: string;
	children: ReactNode;
}) => (
	<div
		key={`footer=section-${key}`}
		style={{ flex: 1 }}
		className={styles['footer-section']}
	>
		{title && (
			<Text
				element="h4"
				variant="headline-3"
				color="color-gray-800"
				className={styles['footer-section-header']}
			>
				{title}
			</Text>
		)}
		{children}
	</div>
);

const Footer = ({
	contact,
	copyright,
	linkBlocks,
	logo,
	policyLinks,
	socialLinks,
}: FooterProps) => {
	const displayType = useDisplayType();
	const isMinDesktop = displayType === 'desktop';

	const renderMain = () => {
		return (
			<div className={styles['footer-contact']}>
				{renderBlock({
					title: 'Contact us',
					key: 'contact',
					children: contact,
				})}
				{!!socialLinks.length &&
					renderBlock({
						title: 'Social',
						key: 'social',
						children: (
							<div className={styles['footer-social']}>
								{socialLinks.map((sLink) => (
									<a
										key={sLink.url}
										href={sLink.url}
										target={sLink.openInNewTab ? '_blank' : '_self'}
										className={classNames(styles['footer-social-icon'])}
										rel="noreferrer"
									>
										<StandaloneIcon
											color="color-gray-800"
											icon={sLink.iconType}
											title={sLink.title}
											size="size-600"
										/>
									</a>
								))}
							</div>
						),
					})}
			</div>
		);
	};

	const renderPolicyLinks = (links: FooterLink[]) => {
		return [
			links.map((link) => (
				<a
					key={link.url}
					href={link.url}
					className={styles['policy-link']}
					target={link.openInNewTab ? '_blank' : '_self'}
					rel="noreferrer"
				>
					<Text variant="body-small" className={styles['policy-link-text']}>
						{link.text}
					</Text>
				</a>
			)),
			<a
				key="onetrust"
				href="#"
				onClick={() => (window as any).OneTrust.ToggleInfoDisplay()}
				className={styles['policy-link']}
			>
				<Text variant="body-small" className={styles['policy-link-text']}>
					Your Privacy Choices{' '}
					<img
						src="https://branding.directsupply.com/assets/dsi/legal/opt-out.png"
						className={styles['onetrust-image']}
					/>
				</Text>
			</a>,
		];
	};

	// if this is mobile, combine columns if the category is the same, in case items were split for formatting
	const reducedBlocks = isMinDesktop
		? linkBlocks
		: linkBlocks.reduce<FooterLinkBlock[]>((acc, block) => {
				const existingBlock = acc.find((b) => b.category === block.category);
				if (existingBlock) {
					existingBlock.links = [...existingBlock.links, ...block.links];
					return acc;
				}
				return [...acc, { ...block }];
		  }, []);

	// cap columns between 3 and 4
	const colCount = Math.min(4, Math.max(3, reducedBlocks.length + 1));

	return (
		<footer className={styles['footer']}>
			<div className={styles['footer-logo']}>{logo}</div>
			<div
				className={classNames(styles['footer-main'], styles[`col-${colCount}`])}
			>
				{renderMain()}
				{renderLinkBlocks(reducedBlocks)}
			</div>
			<div className={styles['footer-info']}>
				<Text variant="body-small" className={styles['copyright']}>
					{copyright}
				</Text>
				<span className={styles['policy']}>
					{renderPolicyLinks(policyLinks)}
				</span>
			</div>
		</footer>
	);
};

export { Footer };
