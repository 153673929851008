import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollManagement() {
	const { pathname, hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const element = document.getElementById(hash.substring(1));
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		} else {
			window.scrollTo(0, 0);
		}
	}, [pathname, hash]);
}

export { useScrollManagement };
