import { Image, Text, ButtonLink, classNames } from '@dsx/react';
import styles from './StoryDetailPageHero.module.css';
import { getMagnoliaBaseDamUrl } from '../../../util/getMagnoliaBaseUrl';
import { useDisplayType } from '../../../hooks';
import {
	extractPageHeroButtonProps,
	getHeadlineVariantForSize,
	getSubHeadlineVariantForSize,
} from '../utils';
import type { PageHeroButton, StoryDetailPageHeroProps } from '../types';

const StoryDetailPageHero = (props: StoryDetailPageHeroProps) => {
	const {
		title,
		lead,
		pageHeroButtons: pageHeroButtonsNode,
		featuredImage,
	} = props;
	const pageHeroButtons =
		pageHeroButtonsNode && extractPageHeroButtonProps(pageHeroButtonsNode);
	const magnoliaBaseDamUrl = getMagnoliaBaseDamUrl();
	const featuredImageUrl = `${magnoliaBaseDamUrl}${featuredImage['@link']}`;
	const displayTarget = useDisplayType();
	const headlineVariant = getHeadlineVariantForSize(displayTarget, 'subpage');

	const renderButtons = (buttons: PageHeroButton[]) => {
		return <div className={styles['buttons']}>{buttons.map(renderButton)}</div>;
	};

	const renderButton = (button: PageHeroButton, index: number) => {
		const linkTarget = button.openInNewTab ? '_blank' : '_self';
		return (
			<ButtonLink
				key={`button-${index}`}
				target={linkTarget}
				variant={index == 0 ? 'primary' : 'default'}
				href={button.link}
				size="large"
			>
				{button.text}
			</ButtonLink>
		);
	};

	return (
		<div>
			<section className={classNames(styles['page-header'], styles['light'])}>
				<div
					className={classNames(
						styles['page-header-container'],
						styles['light'],
						styles['subpage']
					)}
				>
					<div
						className={classNames(
							styles['page-header-content'],
							styles['light']
						)}
					>
						<Text
							element="h1"
							variant={headlineVariant}
							className={styles['heading']}
						>
							{title}
						</Text>
						{lead && (
							<Text
								element="span"
								variant={getSubHeadlineVariantForSize(displayTarget)}
								className={styles['subheading']}
							>
								<div dangerouslySetInnerHTML={{ __html: lead }} />
							</Text>
						)}
						{pageHeroButtons && renderButtons(pageHeroButtons)}
					</div>
					<div className={classNames(styles['imageContainer'])}>
						<Image
							src={featuredImageUrl}
							alt="Stories Image"
							objectFit="cover"
							width="100%"
							height="100%"
							className={styles['heroImage']}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export { StoryDetailPageHero };
