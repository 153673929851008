import { useDisplayType } from '../../hooks';
import styles from './TopNav.module.css';
import { ButtonLink, StandaloneIcon, classNames } from '@dsx/react';
import type { ReactNode, RefObject } from 'react';
import { createRef, useEffect, useRef, useState } from 'react';
import type { Logo, MenuContent, MenuLink } from './types';
import { TopNavPrimaryItem } from './TopNavPrimaryItem';
import { TopNavPrimaryItemMobile } from './TopNavPrimaryItemMobile';
import { TopNavBarMobile } from './TopNavBarMobile';
import { Link } from 'react-router-dom';
import { updateActiveContent } from './utils';

const renderDesktopCTA = (ctaContent: MenuLink[]) => {
	if (ctaContent.length === 0) {
		return null;
	}
	return (
		<div className={styles['top-nav-cta']}>
			{ctaContent.map(({ path, value, icon, label, openInNewTab }, index) => (
				<ButtonLink
					key={path}
					href={value}
					target={openInNewTab ? '_blank' : '_self'}
					rel="nofollow"
					variant={index == 0 ? 'primary' : 'default'}
					className={classNames(styles['menu-item-link'])}
					icon={icon}
				>
					{label}
				</ButtonLink>
			))}
		</div>
	);
};

type ContentOptions = {
	menuRef: RefObject<HTMLUListElement>;
	toggleMenuRefs: RefObject<HTMLDivElement>[];
	items: MenuContent[];
	ctaLinks: MenuLink[];
	activeItemId: string;
	header?: ReactNode;
	isOpen?: boolean;
	onSelect: (id: string) => void;
};

const renderDesktopContent = ({
	menuRef,
	toggleMenuRefs,
	items,
	ctaLinks,
	activeItemId,
	header,
	isOpen,
	onSelect,
}: ContentOptions) => {
	return (
		<div className={styles['top-nav-primary-content']}>
			<div className={styles['top-nav-primary-menu']}>
				{items.map((item, index) => (
					<TopNavPrimaryItem
						ref={toggleMenuRefs[index]}
						menuRef={menuRef}
						key={item.primary.path}
						menuItem={item}
						activeItemId={activeItemId}
						onSelect={onSelect}
						isOpen={isOpen && activeItemId == item.primary.path}
					/>
				))}
			</div>
			{header && <div className={styles['top-nav-header']}>{header}</div>}
			{renderDesktopCTA(ctaLinks)}
		</div>
	);
};

const renderMobileMenu = (
	menuRef: RefObject<HTMLUListElement>,
	menuContents: MenuContent[],
	ctaLinks: MenuLink[]
) => {
	return (
		<ul ref={menuRef} className={styles['top-nav-mobile-menu']}>
			{menuContents.map((mc) => (
				<TopNavPrimaryItemMobile key={mc.primary.path} item={mc} />
			))}
			{ctaLinks.map((ctaLink, index) => (
				<li
					key={ctaLink.path}
					className={classNames(
						!!menuContents.length && index == 0 ? styles['first-cta'] : ''
					)}
				>
					<Link
						to={ctaLink.value}
						target={ctaLink.openInNewTab ? '_blank' : '_self'}
						className={styles['mobile-cta-link']}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '4px',
						}}
					>
						{ctaLink.icon && (
							<StandaloneIcon title={ctaLink.label} icon={ctaLink.icon} />
						)}
						{ctaLink.label}
					</Link>
				</li>
			))}
		</ul>
	);
};

type TopNavProps = {
	activePath: string;
	initMenuContent: MenuContent[];
	ctaLinks: MenuLink[];
	logo: Logo;
	header?: ReactNode;
};

const initializeMenuRefs = (
	refs: RefObject<HTMLDivElement>[],
	menuContent: MenuContent[]
) =>
	Array(menuContent.length)
		.fill(null)
		.map((_, i) => refs[i] || createRef());

const TopNav = ({
	activePath,
	initMenuContent,
	ctaLinks,
	header,
	logo,
}: TopNavProps) => {
	const displayType = useDisplayType();
	const isMinDesktop = displayType === 'desktop';

	const [open, setOpen] = useState(false);
	const [activeItemId, setActiveItemId] = useState<string>('');
	const [menuContent, setMenuContent] = useState<MenuContent[]>(
		updateActiveContent(initMenuContent, activePath)
	);
	const menuRef = useRef<HTMLUListElement>(null);
	const toggleButtonRef = useRef<HTMLDivElement>(null);

	const [toggleMenuRefs] = useState<RefObject<HTMLDivElement>[]>(
		initializeMenuRefs([], menuContent)
	);

	const handleMobileMenuToggled = () => {
		setOpen((currentState) => !currentState);
	};

	// TODO: consider other scenarios for accesibility
	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			if (
				isMinDesktop &&
				toggleMenuRefs.every(
					(ref) => ref.current && !ref.current.contains(event.target as Node)
				)
			) {
				setActiveItemId('');
				setOpen(false);
			} else if (
				toggleButtonRef.current &&
				!toggleButtonRef.current.contains(event.target as Node)
			) {
				setOpen(false);
			}
		}
	};

	useEffect(() => {
		setActiveItemId('');
		setOpen(false);
		setMenuContent((current) => updateActiveContent(current, activePath));
	}, [activePath]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<header className={styles['top-nav']}>
			<div className={styles['top-nav-content']}>
				<div className={styles['top-nav-logo']}>
					<a href={logo.href}>
						<img src={logo.svg} alt={logo.alt} className={styles['logo']} />
					</a>
				</div>

				{isMinDesktop ? (
					renderDesktopContent({
						menuRef,
						toggleMenuRefs,
						items: menuContent,
						ctaLinks,
						activeItemId,
						header,
						isOpen: open,
						onSelect: (value: string) => {
							setActiveItemId(value);
							setOpen(value !== ''); // if a non-empty value came back, the menu should be open
						},
					})
				) : (
					<TopNavBarMobile
						toggleButtonRef={toggleButtonRef}
						isOpen={open}
						onSelect={handleMobileMenuToggled}
					/>
				)}
			</div>
			{!isMinDesktop &&
				open &&
				renderMobileMenu(menuRef, menuContent, ctaLinks)}
		</header>
	);
};

export { TopNav };
