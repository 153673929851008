import {
	CallToAction,
	ImageGrid,
	CollateralSpotlight,
	ContactForm,
	ContactFormGated,
	FeaturedMenu,
	Wysiwyg,
	IconSequence,
	IconDescription,
	ProductGrid,
	ImageSpotlight,
	ProductSpotlight,
	Biography,
	Testimonial,
	Video,
	DssiSavingsCalculator,
	Table,
} from '@ds-mgnl/marketing';
import { getMagnoliaBaseDamUrl } from '../../util/getMagnoliaBaseUrl';

export interface ConditionalBlockComponentProps {
	blockType:
		| 'featuredMenuBlock'
		| 'callToActionBlock'
		| 'contactFormBlock'
		| 'contactFormGatedBlock'
		| 'wysiwigBlock'
		| 'iconSequenceBlock'
		| 'iconDescriptionBlock'
		| 'imageGridBlock'
		| 'productGridBlock'
		| 'collateralSpotlightBlock'
		| 'imageSpotlightBlock'
		| 'productSpotlightBlock'
		| 'testimonialBlock'
		| 'biographyBlock'
		| 'videoBlock'
		| 'dssiSavingsCalculatorBlock'
		| 'tableBlock';
	blockData: any;
}

export const ConditionalBlockComponent = ({
	blockType,
	blockData,
}: ConditionalBlockComponentProps) => {
	const magnoliaBaseDamUrl = getMagnoliaBaseDamUrl();
	switch (blockType) {
		case 'featuredMenuBlock':
			return (
				<FeaturedMenu {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'callToActionBlock':
			return <CallToAction {...blockData} />;
		case 'wysiwigBlock':
			return <Wysiwyg {...blockData} />;
		case 'iconSequenceBlock':
			return (
				<IconSequence {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'iconDescriptionBlock':
			return (
				<IconDescription {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'imageGridBlock':
			return <ImageGrid {...blockData} externalDamHost={magnoliaBaseDamUrl} />;
		case 'productGridBlock':
			return (
				<ProductGrid {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'collateralSpotlightBlock':
			return (
				<CollateralSpotlight
					{...blockData}
					externalDamHost={magnoliaBaseDamUrl}
				/>
			);
		case 'imageSpotlightBlock':
			return (
				<ImageSpotlight {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'productSpotlightBlock':
			return (
				<ProductSpotlight {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'testimonialBlock':
			return (
				<Testimonial {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'biographyBlock':
			return <Biography {...blockData} externalDamHost={magnoliaBaseDamUrl} />;
		case 'videoBlock':
			return <Video {...blockData} externalDamHost={magnoliaBaseDamUrl} />;
		case 'contactFormBlock':
			return (
				<ContactForm {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'contactFormGatedBlock':
			return (
				<ContactFormGated {...blockData} externalDamHost={magnoliaBaseDamUrl} />
			);
		case 'dssiSavingsCalculatorBlock':
			return (
				<DssiSavingsCalculator
					{...blockData}
					externalDamHost={magnoliaBaseDamUrl}
				/>
			);
		case 'tableBlock':
			return <Table {...blockData} externalDamHost={magnoliaBaseDamUrl} />;
		default:
			console.log(`${blockType} not found.`);
			return null;
	}
};
