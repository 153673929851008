// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* gray */
/* var(--color-gray-100): #e0e4e7 */
/* var(--color-gray-200): #c1c9d0 */
/* var(--color-gray-300): #a2adb8 */

.TopNavBarMobile-module_dropdown-container_HZi {
	padding: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.TopNavBarMobile-module_arrow-container_jgh {
    padding: 8px;
    border-radius: 4px;
    background-color: #e0e4e7; /* var(--color-gray-100) */
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopNavBarMobile-module_arrow-container_jgh:hover {
    background-color: #c1c9d0; /* var(--color-gray-200) */
}

.TopNavBarMobile-module_arrow-container_jgh.TopNavBarMobile-module_active_bSe {
    background-color: #a2adb8; /* var(--color-gray-300) */
}`, "",{"version":3,"sources":["webpack://./src/components/TopNav/TopNavBarMobile.module.css"],"names":[],"mappings":"AAAA,SAAS;AACT,mCAAmC;AACnC,mCAAmC;AACnC,mCAAmC;;AAEnC;CACC,YAAY;IACT,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,0BAA0B;IACrD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;AACzD;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;AACzD","sourcesContent":["/* gray */\n/* var(--color-gray-100): #e0e4e7 */\n/* var(--color-gray-200): #c1c9d0 */\n/* var(--color-gray-300): #a2adb8 */\n\n.dropdown-container {\n\tpadding: 8px;\n    display:flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.arrow-container {\n    padding: 8px;\n    border-radius: 4px;\n    background-color: #e0e4e7; /* var(--color-gray-100) */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.arrow-container:hover {\n    background-color: #c1c9d0; /* var(--color-gray-200) */\n}\n\n.arrow-container.active {\n    background-color: #a2adb8; /* var(--color-gray-300) */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-container": `TopNavBarMobile-module_dropdown-container_HZi`,
	"arrow-container": `TopNavBarMobile-module_arrow-container_jgh`,
	"active": `TopNavBarMobile-module_active_bSe`
};
export default ___CSS_LOADER_EXPORT___;
