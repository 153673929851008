import { TopNav } from '../TopNav';
import type { MenuContent, MenuLink } from '../TopNav';
import logoSvg from '../../direct-supply-dssi-logo.png';
import { MdLock } from '@dsx/react';
import { useLocation } from 'react-router-dom';

const menuContent: MenuContent[] = [
	{
		primary: {
			path: '/solutions',
			label: 'Solutions',
			value: '',
			openInNewTab: false,
		},
		secondary: [
			{
				path: '/solutions/spend-optimization',
				label: 'Spend Optimization',
				value: '/solutions/spend-optimization',
				openInNewTab: false,
				tertiary: [
					{
						path: '/solutions/spend-optimization/supply-chain-consulting',
						label: 'Supply Chain Consulting',
						value: '/solutions/spend-optimization/supply-chain-consulting',
						openInNewTab: false,
					},
					{
						path: '/solutions/spend-optimization/ai-in-procurement',
						label: 'AI in Procurement',
						value: '/solutions/spend-optimization/ai-in-procurement',
						openInNewTab: false,
					},
					{
						path: '/solutions/spend-optimization/food-procurement',
						label: 'Food Procurement',
						value: '/solutions/spend-optimization/food-procurement',
						openInNewTab: false,
					},
				],
			},
			{
				path: '/solutions/procure-to-pay',
				label: 'Procure to Pay',
				value: '/solutions/procure-to-pay/',
				openInNewTab: false,
				tertiary: [
					{
						path: '/solutions/procure-to-pay/ap-automationcase',
						label: 'AP Automation',
						value: '/solutions/procure-to-pay/ap-automationcase',
						openInNewTab: false,
					},
					{
						path: '/solutions/procure-to-pay/inventory-management',
						label: 'Inventory Management',
						value: '/solutions/procure-to-pay/inventory-management',
						openInNewTab: false,
					},
					{
						path: '/solutions/procure-to-pay/supply-chain-management',
						label: 'Supply Chain Management',
						value: '/solutions/procure-to-pay/supply-chain-management',
						openInNewTab: false,
					},
				],
			},
		],
	},
	{
		primary: {
			path: '/markets',
			label: 'Markets',
			value: '',
			openInNewTab: false,
		},
		secondary: [
			{
				path: '/markets/senior-living',
				label: 'Senior Living',
				value: '/markets/senior-living',
				openInNewTab: false,
			},
			{
				path: '/markets/behavioral-health',
				label: 'Behavioral Health',
				value: '/markets/behavioral-health',
				openInNewTab: false,
			},
			{
				path: '/markets/acute-health-care',
				label: 'Acute Health Care',
				value: '/markets/acute-health-care',
				openInNewTab: false,
			},
			{
				path: '/markets/hospitality',
				label: 'Hospitality',
				value: '/markets/hospitality',
				openInNewTab: false,
			},
		],
	},
	{
		primary: {
			path: '/resources',
			label: 'Resources',
			value: '',
			openInNewTab: false,
		},
		secondary: [
			{
				path: '/case-studies',
				label: 'Success Stories',
				value: '/case-studies',
				openInNewTab: false,
			},
			{
				path: '/insights',
				label: 'Insights',
				value: '/insights',
				openInNewTab: false,
			},
			{
				path: '/testimonials',
				label: 'Testimonials',
				value: '/testimonials',
				openInNewTab: false,
			},
		],
	},
	{
		primary: {
			path: '/about',
			label: 'About',
			value: '',
			openInNewTab: false,
		},
		secondary: [
			{
				path: '/about/meet-the-team',
				label: 'Meet the Team',
				value: '/about/meet-the-team',
				openInNewTab: false,
			},
			{
				path: '/about/history',
				label: 'History',
				value: '/about/history',
				openInNewTab: false,
			},
		],
	},
	{
		primary: {
			path: '/supply-chain-partners',
			label: 'Supply Chain Partners',
			value: '/supply-chain-partners/',
			openInNewTab: false,
		},
		secondary: [],
	},
];

const getCtaLinks = (): MenuLink[] => {
	return [
		{
			path: 'contact/',
			label: 'Contact us',
			value: 'contact/',
			openInNewTab: false,
		},
		{
			path: 'sign-in',
			label: 'Sign in',
			value: 'https://www.directs.com/dssi/default.aspx',
			icon: MdLock,
			openInNewTab: true,
		},
	];
};

const TopNavDssi = () => {
	const logo = {
		svg: logoSvg,
		alt: 'DS TELS Logo',
		href: '/',
	};

	const { pathname } = useLocation();
	const ctaLinks = getCtaLinks();

	return (
		<TopNav
			activePath={pathname}
			initMenuContent={menuContent}
			ctaLinks={ctaLinks}
			logo={logo}
		/>
	);
};

export { TopNavDssi };
