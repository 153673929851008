import { type JcrNode } from '@magnolia/react-editor';

export function formatCompositeField(items: JcrNode & Record<string, unknown>) {
	if (items) {
		const nodes = items['@nodes'];
		const filteredObj = Object.keys(items)
			.filter((key) => nodes.includes(key))
			.reduce((obj: any, key) => {
				obj[key] = items[key];
				return obj;
			}, {});

		const itemsArray = nodes.map((i: string) => {
			return filteredObj[i];
		});

		return itemsArray;
	}
	return [];
}
