import { Text, Image, ButtonLink } from '@dsx/react';
import styles from './CollateralAssetView.module.css';
import type { UploadedAssetCalendarViewProps } from './types';

export const UploadedAssetCalendarView = ({
	category,
	headline,
	description,
	assetUrl,
	thumbnailImage,
}: UploadedAssetCalendarViewProps) => {
	return (
		<div className={styles['asset-detail-view']}>
			<div className={styles['content-container']}>
				{category && (
					<Text
						element="span"
						variant="highlight-1"
						className={styles['category']}
					>
						{category}
					</Text>
				)}
				{headline && (
					<Text
						element="h1"
						variant="display-headline-4"
						className={styles['headline']}
					>
						{headline}
					</Text>
				)}
				{description && (
					<Text
						element="p"
						variant="subtitle"
						className={styles['description']}
					>
						{description}
					</Text>
				)}
				{assetUrl && (
					<ButtonLink
						href={assetUrl}
						variant="primary"
						target="_blank"
						className={styles['button']}
					>
						Add to calendar
					</ButtonLink>
				)}
			</div>
			<div className={styles['image-container']}>
				<Image
					src={thumbnailImage}
					alt="Collateral Asset Image"
					width="1000px"
					height="1000px"
					className={styles['image']}
				/>
			</div>
		</div>
	);
};
