import {
	MdExpandLess,
	MdExpandMore,
	StandaloneIcon,
	Text,
	classNames,
} from '@dsx/react';
import type { MenuContent } from './types';
import styles from './TopNavPrimaryItem.module.css';
import { Link } from 'react-router-dom';
import { forwardRef, type RefObject } from 'react';

type TopNavPrimaryItemProps = {
	menuRef: RefObject<HTMLUListElement>;
	menuItem: MenuContent;
	activeItemId: string;
	isOpen?: boolean;
	onSelect: (id: string) => void;
};

const renderArrow = (isOpen: boolean) => (
	<span className={styles['arrow-container']}>
		<StandaloneIcon
			icon={isOpen ? MdExpandLess : MdExpandMore}
			title={isOpen ? 'Close' : 'Open'}
		/>
	</span>
);

const renderLink = (
	to: string,
	label: string,
	openInNewTab: boolean,
	active?: boolean
) => (
	<Link
		to={to}
		target={openInNewTab ? '_blank' : '_self'}
		className={classNames(styles['nav-link'], active ? styles['active'] : '')}
	>
		<Text variant="menu-item">{label}</Text>
	</Link>
);

const renderSecondaryNavigation = (
	menuRef: RefObject<HTMLUListElement>,
	menuItem: MenuContent
) => {
	return (
		<ul
			className={classNames(styles['secondary-menu-container'])}
			ref={menuRef}
		>
			{menuItem.secondary.map((smi) => (
				<li
					key={smi.path}
					className={classNames(smi.active ? styles['active'] : '')}
				>
					{renderLink(smi.value, smi.label, smi.openInNewTab, smi.active)}
				</li>
			))}
		</ul>
	);
};

const renderMegaNavigation = (
	menuRef: RefObject<HTMLUListElement>,
	menuItem: MenuContent
) => {
	return (
		<ul className={classNames(styles['mega-menu-container'])} ref={menuRef}>
			{menuItem.secondary.map((smi) => (
				<li
					key={smi.path}
					className={classNames(
						smi.active ? styles['active'] : '',
						styles['primary-link']
					)}
				>
					{renderLink(smi.value, smi.label, smi.openInNewTab, smi.active)}
					{smi.tertiary?.map((tmi) => (
						<span
							key={tmi.path}
							className={classNames(
								tmi.active ? styles['active'] : '',
								styles['sub-link']
							)}
						>
							{renderLink(tmi.value, tmi.label, tmi.openInNewTab, tmi.active)}
						</span>
					))}
				</li>
			))}
		</ul>
	);
};

const renderPrimaryLink = (
	{ primary, secondary }: MenuContent,
	isOpen: boolean,
	onClick: () => void
) => {
	// When a secondary nav is present, the item displays as an active button in cases where the path
	// either matches the url exactly or is a partial match (url starts with path).
	// When a secondary nav is NOT present, the item displays as text with an underline depending on
	// whether or not the url is an EXACT match.
	const hasSecondaryNav = secondary.length > 0;
	return hasSecondaryNav ? (
		<div
			className={classNames(
				styles['primary-menu-item-content'],
				primary.active ? styles['active'] : ''
			)}
			onClick={onClick}
		>
			<>
				<Text variant="menu-item">{primary.label}</Text>
				{renderArrow(!!isOpen)}
			</>
		</div>
	) : (
		<Link
			to={primary.value}
			className={classNames(styles['primary-nav-link'])}
			target={primary.openInNewTab ? '_blank' : '_self'}
		>
			<div
				className={classNames(styles['primary-menu-item-content'])}
				onClick={onClick}
			>
				<Text variant="menu-item">{primary.label}</Text>
			</div>
		</Link>
	);
};

const TopNavPrimaryItem = forwardRef(
	(
		{
			menuRef,
			menuItem,
			activeItemId,
			isOpen,
			onSelect,
		}: TopNavPrimaryItemProps,
		ref
	) => {
		const { primary, secondary } = menuItem;
		const isSelected = primary.path === activeItemId;
		const hasMegaNav = secondary.some(
			(smi) => smi.tertiary && smi.tertiary.length > 0
		);
		const hasSecondaryNav = secondary.length > 0 && !hasMegaNav;

		const handleMenuItemClick = () => {
			onSelect(isSelected ? '' : primary.path);
		};

		return (
			<div
				key={primary.path}
				className={classNames(
					styles['primary-menu-item'],
					primary.active ? styles['active'] : '',
					primary.active && !hasSecondaryNav ? styles['active-item'] : '',
					isSelected ? styles['selected'] : ''
				)}
				ref={ref as RefObject<HTMLDivElement>}
			>
				{renderPrimaryLink(menuItem, !!isOpen, handleMenuItemClick)}
				{isOpen &&
					hasSecondaryNav &&
					renderSecondaryNavigation(menuRef, menuItem)}
				{isOpen && hasMegaNav && renderMegaNavigation(menuRef, menuItem)}
			</div>
		);
	}
);

export { TopNavPrimaryItem };
