// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterDssi-module_contact-link_iAA {
	font-weight: normal;
	text-decoration: none;
	word-break: break-word;
	word-wrap: break-word;
	font-family: "Inter var", sans-serif;
}

.FooterDssi-module_contact-link_iAA:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/FooterDssi/FooterDssi.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,qBAAqB;CACrB,sBAAsB;CACtB,qBAAqB;CACrB,oCAAoC;AACrC;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".contact-link {\n\tfont-weight: normal;\n\ttext-decoration: none;\n\tword-break: break-word;\n\tword-wrap: break-word;\n\tfont-family: \"Inter var\", sans-serif;\n}\n\n.contact-link:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact-link": `FooterDssi-module_contact-link_iAA`
};
export default ___CSS_LOADER_EXPORT___;
