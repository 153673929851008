// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentContainer-module_content-container_i2p {
    max-width: 1280px;
    margin: 0 auto;
    padding: 3rem 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/ContentContainer/ContentContainer.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".content-container {\n    max-width: 1280px;\n    margin: 0 auto;\n    padding: 3rem 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-container": `ContentContainer-module_content-container_i2p`
};
export default ___CSS_LOADER_EXPORT___;
