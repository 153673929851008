import {
	Text,
	Image,
	StandaloneIcon,
	MdOutlinePlayCircleFilled,
	classNames,
} from '@dsx/react';
import styles from './CollateralAssetViewVideo.module.css';
import { useState } from 'react';
import type { ExternalAssetVideoViewProps } from './types';

export const ExternalAssetVideoView = ({
	category,
	headline,
	description,
	publishedDate,
	createdDate,
	thumbnailImage,
	videoId,
	videoType,
	videoDuration,
}: ExternalAssetVideoViewProps) => {
	const [showOverlay, setShowOverlay] = useState(true);
	const videoUrl =
		videoType === 'vimeo'
			? `https://player.vimeo.com/video/${videoId}`
			: `https://www.youtube.com/embed/${videoId}`;
	const videoUrlWithParams = new URL(videoUrl);
	const overlayDisplay = showOverlay ? 'show' : 'hide';
	!showOverlay && videoUrlWithParams.searchParams.append('autoplay', '1');

	const handleOverlayToggle = () => {
		setShowOverlay(false);
	};

	return (
		<div className={styles['asset-detail-view']}>
			<div className={styles['content-container']}>
				{category && (
					<Text
						element="span"
						variant="highlight-1"
						className={styles['category']}
					>
						{category}
					</Text>
				)}
				{headline && (
					<Text
						element="h1"
						variant="display-headline-4"
						className={styles['headline']}
					>
						{headline}
					</Text>
				)}
				{description && (
					<Text
						element="p"
						variant="subtitle"
						className={styles['description']}
					>
						{description}
					</Text>
				)}
				<div className={styles['asset-details']}>
					{createdDate && (
						<Text element="p" variant="body-small">
							Published Date: {createdDate}
						</Text>
					)}
					{publishedDate && (
						<Text element="p" variant="body-small">
							Modified Date: {publishedDate}
						</Text>
					)}
					{videoDuration && (
						<Text element="p" variant="body-small">
							Duration: {videoDuration}
						</Text>
					)}
				</div>
			</div>

			<div className={styles['video-container']}>
				<iframe
					src={`${videoUrlWithParams}`}
					className={styles['video-iframe']}
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
				></iframe>
				<div
					onClick={handleOverlayToggle}
					className={classNames(styles['video-image'], styles[overlayDisplay])}
				>
					<Image
						src={thumbnailImage}
						alt="Video Overlay Image"
						objectFit="cover"
						className={styles['image']}
					/>
					<div className={styles['play-icon']}>
						<StandaloneIcon
							icon={MdOutlinePlayCircleFilled}
							title="Play Video"
							size="size-900"
							color="color-gray-0"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
