import type { MenuContent } from '../types';

// If the menu item has no secondary items, then the active state is determined by the primary path
// being an EXACT match to the URL. If the menu item has secondary or tertiary items, then the active state is
// determined by the URL ending with the primary path.
const updateActiveContent = (
	menuContent: MenuContent[],
	activePath: string
) => {
	const trimmedPathname = activePath.replace(/\/$/, '');
	return menuContent.reduce<MenuContent[]>((acc, item) => {
		return [
			...acc,
			{
				...item,
				primary: {
					...item.primary,
					active:
						!item.isAssetLink && item.secondary.length
							? trimmedPathname.startsWith(item.primary.path.replace(/\/$/, ''))
							: trimmedPathname === item.primary.path.replace(/\/$/, ''),
				},
				secondary: item.secondary.map((secondaryItem) => ({
					...secondaryItem,
					active:
						!item.isAssetLink &&
						trimmedPathname.endsWith(secondaryItem.path.replace(/\/$/, '')),
					tertiary: secondaryItem.tertiary?.map((tertiaryItem) => ({
						...tertiaryItem,
						active:
							!item.isAssetLink &&
							trimmedPathname.endsWith(tertiaryItem.path.replace(/\/$/, '')),
					})),
				})),
			},
		];
	}, []);
};

export { updateActiveContent };
