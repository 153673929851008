const getMagnoliaBaseUrl = () => {
	const url = new URL(window.location.href);
	const magnoliaBaseUrl = url.searchParams.has('cmsPreview')
		? process.env.MGNL_BASE_AUTHOR_URL
		: process.env.MGNL_BASE_PUBLIC_URL;
	return magnoliaBaseUrl;
};

const getMagnoliaBaseDamUrl = () => {
	const url = new URL(window.location.href);
	const magnoliaBaseDamUrl = url.searchParams.has('cmsPreview')
		? process.env.MGNL_BASE_DAM_AUTHOR_URL
		: process.env.MGNL_BASE_DAM_PUBLIC_URL;
	return magnoliaBaseDamUrl;
};

export { getMagnoliaBaseUrl, getMagnoliaBaseDamUrl };
