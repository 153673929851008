import { useState } from 'react';
import styles from './TopNavPrimaryItemMobile.module.css';
import type { MenuContent } from './types';
import {
	MdExpandLess,
	MdExpandMore,
	StandaloneIcon,
	Text,
	classNames,
} from '@dsx/react';
import { Link } from 'react-router-dom';

type TopNavPrimaryItemMobileProps = {
	item: MenuContent;
};

const renderArrow = (isOpen: boolean) => (
	<span className={styles['arrow-container']}>
		<StandaloneIcon
			icon={isOpen ? MdExpandLess : MdExpandMore}
			title={isOpen ? 'Close' : 'Open'}
		/>
	</span>
);

const renderLink = (
	to: string,
	label: string,
	openInNewTab: boolean,
	active?: boolean
) => (
	<Link
		className={classNames(
			styles['mobile-link'],
			active ? styles['active'] : ''
		)}
		to={to}
		target={openInNewTab ? '_blank' : '_self'}
	>
		{label}
	</Link>
);

const renderPrimaryLink = (item: MenuContent) => {
	return item.isAssetLink ? (
		<a
			className={classNames(styles['mobile-link'])}
			href={item.primary.value}
			target={item.primary.openInNewTab ? '_blank' : '_self'}
			rel="noopener noreferrer"
		>
			{item.primary.label}
		</a>
	) : (
		renderLink(
			item.primary.value,
			item.primary.label,
			item.primary.openInNewTab
		)
	);
};

const TopNavPrimaryItemMobile = ({ item }: TopNavPrimaryItemMobileProps) => {
	const [open, setOpen] = useState(false);

	const hasSecondaryContent = item.secondary.length > 0;

	const handleSelected = () => {
		setOpen((currentState) => !currentState);
	};

	return (
		<>
			<li
				key={item.primary.path}
				className={classNames(
					styles['primary-item'],
					item.primary.active ? styles['active'] : '',
					hasSecondaryContent ? styles['primary-item-menu'] : ''
				)}
				onClick={handleSelected}
			>
				{hasSecondaryContent && <Text>{item.primary.label}</Text>}
				{hasSecondaryContent && renderArrow(open)}
				{!hasSecondaryContent && renderPrimaryLink(item)}
			</li>
			{hasSecondaryContent &&
				open &&
				item.secondary.map((sm) => (
					<li
						key={sm.path}
						className={classNames(
							styles['secondary-item'],
							sm.active ? styles['active'] : '',
							sm.tertiary && sm.tertiary.length > 0
								? styles['has-tertiary']
								: ''
						)}
					>
						{renderLink(sm.value, sm.label, sm.openInNewTab, sm.active)}
						{sm.tertiary?.map((tmi) => (
							<span
								key={tmi.path}
								className={classNames(
									tmi.active ? styles['active'] : '',
									styles['sub-link']
								)}
							>
								{renderLink(tmi.value, tmi.label, tmi.openInNewTab, tmi.active)}
							</span>
						))}
					</li>
				))}
		</>
	);
};

export { TopNavPrimaryItemMobile };
